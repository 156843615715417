import type { PreviousUpload } from "tus-js-client";
import { Upload } from "tus-js-client";
import axios from "../core/axios";
import { Headers } from "./generic";
import type {
  CollectionResult,
  Result,
  VideoCreationEntity,
  VideoEntity,
  VideoListParams,
} from "../types";
import { assertSuccess, paramsParser } from "../core";

export class Video {
  static async getVideos(params: VideoListParams, auth?: string) {
    const res = await axios.get<CollectionResult<VideoEntity>>(
      `/video/list` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(res.data);
  }

  static async create(postId: string, auth: string) {
    const formData = new FormData();

    const resp = await axios.post<Result<VideoCreationEntity>>(
      `/video/create/${postId}`,
      {
        thumbnailTime: 0,
        title: postId,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async upload(
    meta: {
      AuthorizationSignature: string;
      AuthorizationExpire: string;
      VideoId: string;
      LibraryId: string;
    },
    video: File | Blob,
    extra?: {
      title?: string;
      onError?: (error: any) => void;
      onProgress?: (bytesUploaded: number, bytesTotal: number) => void;
      onSuccess?: () => void;
    },
  ) {
    const upload = new Upload(video, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
      headers: {
        ...meta,
      },
      metadata: {
        filetype: video.type,
        // title: extra?.title,
      },
      onError: extra?.onError,
      onProgress: extra?.onProgress,
      onSuccess: extra?.onSuccess,
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (
      previousUploads: PreviousUpload[],
    ) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });

    return upload;
  }
}
